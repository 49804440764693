import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import Link from 'components/GatsbyLinkWrapper'
import OpenVideo from 'src/components/OpenVideo'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Modal } from 'src/components/Modal'
import ModalCall from 'src/components/UI/Forms/OpenAccountFundPortabilityForm/index'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import usePageQuery from '../../pageQuery'
import headerJsonPF from './../../assets/data/headerPF.json'
import headerJsonPJ from './../../assets/data/headerPJ.json'

import { WIDTH_MD } from 'src/styles/breakpoints'
import { modalProps, FundingDocumentation } from './types'
import { ButtonVideo, SectionWrapper, GridText, Top50 } from './style'
export interface ISendDatalayerEventsProps extends modalProps {
  sendDatalayerEvent: Function;
  type: string;
}

const Hero = ({ pageContext, sendDatalayerEvent, type }: ISendDatalayerEventsProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ linkHeroMaterial, setLinkHeroMaterial ] = useState('')
  const materialLink = pageContext.documentacaoDoFundo.map((item: FundingDocumentation) => item)
  const pathBreadCrumb = type === 'PJ' ? headerJsonPJ : headerJsonPF

  const ModalCallRight = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <ModalCall closeModal={() => setIsModalOpen(false)} customOrigin='Fundos de Investimento' linkIdQrCode={pageContext.id} />
    </Modal>
  )

  useEffect(() => {
    materialLink.map((item: FundingDocumentation) => {
      if (item.nome === 'Material de Divulgação') {
        setLinkHeroMaterial(item.link)
      }
    })
  }, [ linkHeroMaterial ])

  const handleModal = () => {
    setIsModalOpen(true)
    sendDatalayerEvent({
      section: 'dobra_1',
      element_action: 'click button',
      element_name: 'Investir nesse fundo',
      section_name: pageContext.pageData.header.title,
      c_page: window.location.href,
    })
  }

  return (
    <SectionWrapper className='py-5 d-flex position-relative justify-content-between align-items-center'>
      {ModalCallRight}
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          {width < 768 && (
            <div className='w-100 pb-4'>
              {pageContext.pageData.header.urlVideo !== ''
                ? (
                  <OpenVideo link={`${pageContext.pageData.header.urlVideo}`}>
                    <ButtonVideo>
                      {pageContext.pageData.header.image !== ''
                        ? <img src={`${pageContext.pageData.header.image}`} alt='Assistir Vídeo' />
                        : <Img fluid={data.investimentsFundsTemplateNoVideo.fluid} alt='Uma mulher sorrindo' />
                      }
                    </ButtonVideo>
                  </OpenVideo>
                )
                : <Img fluid={data.investimentsFundsTemplateNoVideo.fluid} alt='Uma mulher sorrindo' />
              }
            </div>
            )
          }
          <GridText className='col-12 col-md-6'>
            {width >= WIDTH_MD &&
              <div className='final-slug mb-2'>
                <Link
                  to='/'
                  className='d-none d-md-inline'
                ><Home height={24} width={24} color='#6A6E81' />
                </Link>
                { type !== 'PJ'
                  ? (
                    <>
                      <OrangeIcon className='mx-2' icon='arrow-right' color='#161616' size='SM' />
                      <Link
                        to={pathBreadCrumb.breadcrumb[2]}
                        className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                      >
                        {pathBreadCrumb.breadcrumb[0]}
                      </Link>
                      <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                      <Link
                        to={pathBreadCrumb.breadcrumb[3]}
                        className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mx-2'
                      >
                        {pathBreadCrumb.breadcrumb[1]}
                      </Link>
                    </>
                  )
                  : (
                    <>
                      <OrangeIcon icon='arrow-right' color='#161616' size='SM' className='ml-2' />
                      <Link
                        to={pathBreadCrumb.breadcrumb[0]}
                        className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mx-2'
                      >
                        {pathBreadCrumb.breadcrumb[1]}
                      </Link>
                    </>
                  )
                }
                <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{pageContext.pageData.header.title}</p>
              </div>
            }
            <div className='d-flex justify-content-start'>
              <h1 className='fs-24 fs-md-32 fs-lg-48 lh-30 lh-md-40 fw-600 lh-lg-56 text-grayscale--500 mb-4'>
                {pageContext.pageData.header.title}

                {pageContext.filterData.profileData.investorProfile === 'Investidores qualificados' &&
                  <OrangeIcon icon='q-medal' size='MD' color={type === 'PJ' ? '#07605B' : '#FF7A00'} className='ml-md-1' />}
                {pageContext.filterData.isTop50 === true && <Top50 className='ml-1'>Top Funds</Top50>}
              </h1>
            </div>
            <p className={`fs-14 fs-md-16 fs-lg-18 lh-lg-22 lh-20 mb-4 ${width < 768 ? 'text-grayscale--400' : 'text-grayscale--500'}`}>
              {pageContext.pageData.header.subtitleTemplate}
            </p>
            <div className='w-100'>
              {width >= 768
              ? (
                <button
                  className={`btn--lg btn rounded-2 fs-14 fw-600 mt-2 mb-lg-0 text-none w-100 button-width ${type === 'PJ' ? 'bg-green-100 text-white' : 'btn-orange--extra'}`}
                  onClick={handleModal}
                >
                  Investir neste fundo
                </button>
              )
              : (
                <Link
                  title='Investir agora'
                  className={`btn rounded-2 fs-14 fw-700 text-none ${type === 'PJ' ? 'bg-green-100 text-white' : 'btn-orange--extra'}`}
                  to={`${'bancointer://investimentos/investir/fundos/'}${pageContext.id}`}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_1',
                      element_action: 'click button',
                      element_name: 'Investir neste fundo',
                      section_name: pageContext.pageData.header.title,
                      redirect_url: `${'bancointer://investimentos/investir/fundos/'}${pageContext.id}`,
                      c_page: window.location.href,
                    })
                  }}
                >
                  Investir neste fundo
                </Link>
                 )
              }
              {linkHeroMaterial === ''
                ? (
                  <button
                    disabled
                    className='btn--lg btn btn-white border rounded-2 text-none fs-14 fw-600 mt-3 mb-lg-0'
                  >
                    Material de divulgação
                    <OrangeIcon icon='pdf-file' size='MD' color={type === 'PJ' ? '#07605B' : '#FF7A00'} className='pl-2' />
                  </button>

                )
                : (
                  <a
                    href={linkHeroMaterial} target='_blank' rel='noreferrer'
                    className={`btn--lg btn btn-white border border-orange--extra rounded-2 text-none fs-14 fw-600 mt-3 mb-lg-0 ${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`}
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_1',
                        element_action: 'click button',
                        element_name: 'Material de divulgação',
                        section_name: pageContext.pageData.header.title,
                        redirect_url: `${'bancointer://investimentos/investir/fundos/'}${pageContext.id}`,
                      })
                    }}
                  >
                    Material de divulgação
                    <OrangeIcon icon='pdf-file' size='MD' color={type === 'PJ' ? '#07605B' : '#FF7A00'} className='pl-2' />
                  </a>
                )
              }
            </div>
          </GridText>
          {
            width >= 768 &&
            (
              <div className='col-12 col-md-6 col-lg-5 pl-lg-0'>
                {pageContext.pageData.header.urlVideo !== ''
                  ? (
                    <OpenVideo link={`${pageContext.pageData.header.urlVideo}`}>
                      <ButtonVideo>
                        {pageContext.pageData.header.image !== ''
                          ? <img src={`${pageContext.pageData.header.image}`} alt='Assistir Vídeo' />
                          : <Img fluid={data.investimentsFundsTemplateNoVideo.fluid} alt='Uma mulher sorrindo' />
                        }
                      </ButtonVideo>
                    </OpenVideo>
                  )
                  : <Img fluid={data.investimentsFundsTemplateNoVideo.fluid} alt='Uma mulher sorrindo' />
                }
              </div>
            )
          }
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Hero

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const DivLine = styled.div`
  min-height: 2px;
  width: 100%;
  background: white;
  margin-bottom: 8px;
`

export const HelpMembership = styled.div`
  float: right;
  @media ${device.desktopLG} {
    position: relative;
  }
`

export const TooltipHelp = styled.div`
  svg {
    width: 17px;
  }
  .through {
    text-decoration: line-through;
  }
  &.haveCashback {
    width: 110px;
  }

  .membership {
    .tooltip {
      left: 5px;
      top: 34px;
      &::before {
        left: 156px;
      }
      @media ${device.tablet} {
        left: 10px;
      }

      @media ${device.desktopLG} {
        left: -150px;
      }
    }
  }

  .time-limit {
    .tooltip {
      left: 5px;
      top: 34px;
      &::before {
        left: 234px;
        @media ${device.tablet} {
          left: 232px;
        }
      }
      @media ${device.tablet} {
        left: 12px;
      }

      @media ${device.desktopLG} {
        left: -229px;
      }
    }
  }
`

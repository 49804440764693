import React from 'react'

import Logos from '../../../../pages/pra-voce/investimentos/fundos-de-investimento/sections/FundsLogos/_index'
import OrangeIcon from '../../../../components/UI/MarkdownIcon/OrangeDsIcon'

import { AttentionParagraph } from './style'

type langProps = {
  type?: string;
}

const FundsObservations = ({ type }: langProps) => {
  return (
    <section className='py-5 bg-grayscale--100'>
      <div className='container'>
        <div className='row align-items-center mb-md-5'>
          <div className='col-2 col-xl-1'>
            <OrangeIcon
              size='MD'
              color={type === 'PJ' ? '#07605B' : '#FF7A00'}
              icon='attention'
            />
          </div>
          <div className='col-10 col-xl-11'>
            <AttentionParagraph className='mb-0'>
              Fundos de investimento não contam com garantia do administrador, do gestor, de qualquer mecanismo de
              seguro ou Fundo Garantidor de Crédito – FGC. Os fundos utilizam estratégias que podem resultar em
              significativas perdas patrimoniais para seus cotistas. Os fundos podem estar expostos à significativa
              concentração em ativos financeiros de poucos emissores, com os riscos daí decorrentes. Para avaliação
              da performance de um fundo de investimento, é recomendável a análise de, no mínimo 12 (doze) meses.
              Leia o formulário de informações complementares, lâmina de informações essenciais e o regulamento antes de investir.
              Rentabilidade obtida no passado não representa garantia de resultados futuros. A rentabilidade divulgada
              não é liquida de impostos. Os indicadores econômicos são meras referências econômicas, e não metas ou
              parâmetros de performance. Os produtos apresentados podem não ser adequados para todos os investidores.
              Antes de investir verifique as características do fundo e sempre invista de acordo com o seu perfil de investimentos.
              A instituição é remunerada pela distribuição do produto. Para maiores detalhes,
              consulte as
              <a
                href='https://marketing.bancointer.com.br/arquivos/pdf/idtvm/informacoes-sobre-remuneracao-do-distribuidor-idtvm.pdf'
                className={`fw-600 ${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`}
                target='blank'
              > informações sobre a remuneração do distribuidor.
              </a>
            </AttentionParagraph>
          </div>
        </div>
        <Logos type={type} />
      </div>
    </section>
  )
}

export default FundsObservations

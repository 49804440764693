import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.section`

  @media ${device.tablet} {
    min-height: 376px;
  }

  @media ${device.desktopLG} {
    min-height: 534px;
  }

  @media ${device.desktopXL} {
    min-height: 648px;
  }

  .tab-investimentos {
    ul {
      margin-top: 14px;
      border-bottom: 4px solid ${grayscale[100]};
      justify-content: start;
      list-style: none;
      display: flex;
      flex-wrap: no-wrap;
      overflow-x: auto;
      overflow-y: hidden;
      height: 36px;

      li {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        margin-right: 32px;
        line-height: 17px;
        margin-left: 0;

        @media ${device.tablet} {
          margin-right: 22px;
        }

        button {
          padding-bottom: 10px;

          &:first-child {
            padding-left: 0;
          }
        }
      }
    } 
  }
`

import React from 'react'
import { PageProps } from 'gatsby'
import slugify from 'slugify'
import { useLocation } from '@reach/router'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/hero/_Hero'
import FundsInfo from './sections/funds_info/_FundsInfo'
import Analise from './sections/analises/_Analise'
import Tecnical from './sections/tecnicalEspecifications/_Tecnical'
import DocumentacaoFundos from './sections/documentacaoFundos/_documentacaoFundos'
import FundsObservations from './sections/fundsObservations/_fundsObservations'

import { IInvestimentsFundsContextProps } from './types'

type Props = {
  pageContext: IInvestimentsFundsContextProps;
} & PageProps

const InvestimentFunds = ({ pageContext }: Props) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const location = useLocation()
  const typeLocation = location.pathname.includes('/empresas/investimento-empresarial/')
  const type = typeLocation ? 'PJ' : 'PF'

  const contentMetas = {
    slug: typeLocation ? `/empresas/investimento-empresarial/${slugify(String(pageContext.slug), { strict: true }).toLowerCase()}` : `/pra-voce/investimentos/fundos-de-investimento/${slugify(String(pageContext.slug), { strict: true }).toLowerCase()}`,
    metaTitle: `${pageContext.pageData.header.title}` + ' | Inter',
    metaDescription: `${pageContext.pageData.header.subtitle}`,
  }

  return (
    <Wrapper>
      <Layout pageContext={contentMetas}>
        <Hero pageContext={pageContext} sendDatalayerEvent={sendDatalayerEvent} type={type} />
        <FundsInfo pageContext={pageContext} type={type} />
        <Analise pageContext={pageContext} type={type} />
        <Tecnical pageContext={pageContext} type={type} />
        <DocumentacaoFundos pageContext={pageContext} sendDatalayerEvent={sendDatalayerEvent} type={type} />
        <FundsObservations type={type} />
      </Layout>
    </Wrapper>
  )
}

export default InvestimentFunds

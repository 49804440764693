import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white, grayscale } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

import PlayVideo from 'src/assets/images/shared/play-video-orange.png'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  min-height: calc(100vh - 64px);

  @media ${device.tablet} {
    min-height: 500px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  button, a {
    max-width: 100%;

    &:disabled {
      color: ${grayscale['300']};

      &:hover {
        color: ${grayscale['300']};
      }
    }
  }

  @media ${device.desktopXL} {
    .gatsby-image-wrapper {
      width: 456px;
      float: right;
    }
  }
`

export const GridText = styled.div`
  
  div {

    &.final-slug {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const ButtonVideo = styled.button`
  width: 100%;
  position: relative;
  top: 8px;
  border: none;
  background: transparent;

  &:before {
    content: "";
    background: url(${PlayVideo}) no-repeat center center;
    position: absolute;
    background-size: initial;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: 100%;
  }
`

export const Help = styled.div`
  float: right;
  @media ${device.desktopLG} {
    position: relative;
  }
  .tooltip {
    @media ${device.desktopLG} {
      left: -32px;
      top: 36px;
    }
  }
`

export const Top50 = styled.span`
  font-size: 12px;
  color: ${white};
  border-radius: ${rounded['1']};
  background-color: #00AA4F;
  width: 42px;
  height: 16px;
  font-weight: 700;
  padding: 8px 16px;
  position: relative;
  white-space: nowrap;

  @media ${device.tablet} {
    top: -6px;
    padding: 6px 14px;
  }

  @media ${device.desktopLG} {
    top: -10px;
  }
`

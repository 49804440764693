import React from 'react'
import slugify from 'slugify'
import { numberToReal } from '../../../../shared/helpers'

import InfoBox from '../../components/InfoBox/InfoBox'
import TollTip from 'src/components/InvestmentsFilter/ToolTip/_ToolTip'

import { modalProps } from '../hero/types'

import { TolltipHelp, Help, AdmRate, Risk } from './style'

const FundsInfo = ({ pageContext, type }: modalProps) => {
  return (
    <section className='bg-grayscale--100 py-5'>
      <div className='container py-lg-5'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <div className='row'>
              <div className='col-12 col-md-6 mb-4'>
                <InfoBox iconName='dollar' title='Qual é a aplicação mínima inicial?' type={type}>
                  <span className='fs-16 lh-19 text-grayscale--400'>R$ {numberToReal(pageContext.filterData.initialApplication)}</span>
                </InfoBox>
              </div>
              <div className='col-12 col-md-6 mb-4'>
                <InfoBox iconName='investments' title='Benchmark' type={type}>
                  <span className='fs-16 lh-19 text-grayscale--400'>{pageContext.filterData.benchmark}</span>
                </InfoBox>
              </div>
              <div className='col-12 col-md-6'>
                <InfoBox
                  styles='risk' iconName='attention'
                  title='Qual é o grau de risco?'
                  icon={true}
                  type={type}
                  tolltipTitle='Grau de risco'
                  tooltipText='O grau de risco é uma escala que representa a incerteza com relação à rentabilidade ou disponibilidade dos recursos de determinado fundo'
                >
                  <Risk>
                    <span className={slugify(pageContext.filterData.risk.label, { lower: true })}>
                      {pageContext.filterData.risk.label}
                    </span>
                  </Risk>
                </InfoBox>
              </div>
              <div className='col-12 col-md-6 mt-4 mt-md-0'>
                <InfoBox
                  styles='public' iconName='q-medal' title='Público alvo' icon={true} type={type}
                  tolltipTitle={pageContext.filterData.profileData.investorProfile === 'Investidores em geral'
                  ? 'Investidores em geral' : 'Investidores qualificados'}
                  tooltipText={pageContext.filterData.profileData.investorProfile === 'Investidores em geral'
                  ? 'Qualquer pessoa, física ou jurídica, que tenha investimento no mercado financeiro.' : 'Na prática, são pessoas físicas ou jurídicas com investimentos iguais ou superiores a R$ 1 milhão e declaradas por escrito como investidor qualificado. E também aquelas que com certificações aprovadas pelas CVM para atuação como profissionais de investimento.'}
                >
                  <span className='fs-16 lh-19 text-grayscale--400'>{pageContext.filterData.profileData.investorProfile}</span>
                </InfoBox>
              </div>
            </div>
          </div>
          <div className='col col-12 col-lg-6 mt-4 mt-lg-0'>
            <div className='row h-100'>
              <div className='col col-12 col-md-6'>
                <InfoBox iconName='no-taxes' title='Quais sãs as taxas?' type={type}>
                  <div className='d-flex flex-column'>
                    <TolltipHelp className='mt-2'>
                      <span className='fs-12 lh-15 text-grayscale--500 mr-1'>Taxa de administração </span>
                      <Help className='redemptionFeeLg'>
                        <TollTip
                          iconTooltip='help'
                          title='Taxa de administração'
                          text='É uma taxa anual cobrada de todos os cotistas do fundo para custear a administração, gestão e outros serviços prestados ao fundo.'
                          colorIcon={type === 'PJ' ? '#07605B' : '#FF7A00'}
                          sizeIcon='MD'
                        />
                      </Help>
                    </TolltipHelp>
                  </div>
                  <div className='d-flex flex-column'>
                    <AdmRate>
                      <TolltipHelp>
                        <span className='fs-lg-16 fw-400 text-grayscale--500 '>
                          {pageContext.filterData.adminFee.previous}%
                        </span>
                      </TolltipHelp>
                    </AdmRate>
                  </div>
                  <div className='d-flex flex-column'>
                    <TolltipHelp className='mt-2'>
                      <span className='fs-12 lh-15 text-grayscale--500 mr-1'>Taxa de performance </span>
                      <Help className='performance'>
                        <TollTip
                          iconTooltip='help'
                          title={pageContext.filterData.performanceFee.title}
                          text={pageContext.filterData.performanceFee.description}
                          colorIcon={type === 'PJ' ? '#07605B' : '#FF7A00'}
                          sizeIcon='MD'
                        />
                      </Help>
                    </TolltipHelp>
                  </div>
                  <div className='d-flex flex-column'>
                    <AdmRate>
                      <TolltipHelp>
                        <span className='fs-lg-16 fw-400 text-grayscale--500 d-block'>
                          {pageContext.filterData.performanceFee.previous}
                        </span>
                      </TolltipHelp>
                    </AdmRate>
                  </div>
                </InfoBox>
              </div>
              <div className='col col-12 col-md-6 mt-4 mt-md-0'>
                <InfoBox iconName='agenda' title='Quanto tempo para resgatar?' type={type}>
                  <div className='d-flex flex-column'>
                    <TolltipHelp className='mt-2'>
                      <span className='fs-12 lh-15 text-grayscale--500 pr-1'>Cotização do resgate </span>
                      <Help className='saleoff'>
                        <TollTip
                          iconTooltip='help'
                          title={pageContext.filterData.redemptionFee.title}
                          text={pageContext.filterData.redemptionFee.description}
                          colorIcon={type === 'PJ' ? '#07605B' : '#FF7A00'}
                          sizeIcon='MD'
                        />
                      </Help>
                    </TolltipHelp>
                    <span className='fs-lg-16 text-grayscale--500 mr-1'>
                      {pageContext.filterData.redemptionFee.redemption}
                    </span>
                  </div>
                  <div className='d-flex flex-column pt-2'>
                    <TolltipHelp className='mt-2'>
                      <span className='fs-12 lh-15 text-grayscale--500 mr-1'>Liquidação do resgate </span>
                      <Help className='redemptionFeeLg'>
                        <TollTip
                          iconTooltip='help'
                          title={pageContext.filterData.redemptionSettlement.title}
                          text={pageContext.filterData.redemptionSettlement.description}
                          colorIcon={type === 'PJ' ? '#07605B' : '#FF7A00'}
                          sizeIcon='MD'
                        />
                      </Help>
                    </TolltipHelp>
                    <span className='fs-lg-16 text-grayscale--500 mr-1'>
                      {pageContext.filterData.redemptionSettlement.redemption}
                    </span>
                  </div>
                </InfoBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FundsInfo

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const AttentionParagraph = styled.p`
  font-size: 12px;
  line-height: 14.52px;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopXL} {
    font-size: 12px;
    line-height: 14px;
  }
`

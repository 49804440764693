import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const BoxContent = styled.div`
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border-radius: 8px;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;

    color: ${grayscale['500']};
    margin-bottom: 0;
  }
  .icon {
    width: 26px;
    float: right;
    .tooltip {
      top: 89px;
      left: 30px;
      @media ${device.desktopXL} {
        left: 155px;
      }
    }
    svg {
      width: 17px;
    }
  }
  .risk {
    .tooltip {
      &::before {
        left: 151px;
        @media ${device.desktopLG} {
          left: 155px;
        }
      }
      @media ${device.tablet} {
        left: 36px;
      }
      @media ${device.desktopLG} {
        left: 31px;
      }
    }
  }

  .public {

    .tooltip {
      left: 29px;

      &::before {
        left: 86px;

        @media ${device.desktopLG} {
          left: 89px;
        }
      }
      @media ${device.tablet} {
        left: 36px;
      }
      @media ${device.desktopLG} {
        left: 31px;
      }
    }
  }
`

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Documentation = styled.section`

  @media ${device.desktopLG} {
    min-height: 504px;
  }
 
  @media ${device.desktopXL} {
    min-height: 648px;

    .gatsby-image-wrapper {
      width: 456px;
    }
  }
`

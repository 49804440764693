import React, { useState } from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import useWidth from 'src/hooks/window/useWidth'

import { Tooltip, ButtonToolTip } from './style'

type TooltipPrps = {
  iconTooltip: string;
  title?: string;
  text?: string;
  colorIcon: string;
  sizeIcon: string;
}

const ToolTip = ({ iconTooltip, title, text, colorIcon, sizeIcon }: TooltipPrps) => {
  const [ open, setOpen ] = useState(false)

  const WIDTH_LG = 1024
  const windowWidth = useWidth(300)

  return (
    <>
      { windowWidth >= WIDTH_LG
        ? (
          <ButtonToolTip onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)}>
            <OrangeIcon size={sizeIcon} icon={iconTooltip} color={colorIcon} />
          </ButtonToolTip>
          )
        : (
          <ButtonToolTip onClick={() => setOpen(!open)}>
            <OrangeIcon size={sizeIcon} icon={iconTooltip} color={colorIcon} />
          </ButtonToolTip>)
      }

      {open &&
        <Tooltip className='tooltip'>
          <p className='fs-12 lh-15 fw-700 text-white text-left'>{title}</p>
          <p className='fs-12 lh-15 text-white text-left'>{text}</p>
        </Tooltip>
      }
    </>
  )
}

ToolTip.defaultProps = {
  iconTooltip: '',
  title: '',
  text: '',
  colorIcon: '',
  sizeIcon: 'MD',
}

export default ToolTip

import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      investimentsFundsTemplate: imageSharp(fluid: { originalName: { regex: "/bg-investiments-funds/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      investimentsFundsTemplateNoVideo: imageSharp(fluid: { originalName: { regex: "/bg-sem-video-fundos-investimento/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      mulherAnalisandoFundos: imageSharp(fluid: { originalName: { regex: "/mulher-analisando-fundos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      documentacaoFundos: imageSharp(fluid: { originalName: { regex: "/fundos-investimentos-template/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      anbima: imageSharp(fluid: { originalName: { regex: "/ambima/" }}) {
        fluid(maxWidth: 107, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      autorregulacaoAnbima: imageSharp(fluid: { originalName: { regex: "/autorregulacao-ambima/" }}) {
        fluid(maxWidth: 107, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      comoInvestir: imageSharp(fluid: { originalName: { regex: "/como-investir/" }}) {
        fluid(maxWidth: 107, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      distribuicaoAnbima: imageSharp(fluid: { originalName: { regex: "/distribuicao-ambima/" }}) {
        fluid(maxWidth: 107, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      ofertasPublicasInvestiments: imageSharp(fluid: { originalName: { regex: "/ofertas-publicas-investiments/" }}) {
        fluid(maxWidth: 107, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      anbima2: imageSharp(fluid: { originalName: { regex: "/ambima-2/" }}) {
        fluid(maxWidth: 107, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      broker1: imageSharp(fluid: { originalName: { regex: "/broker-1/" }}) {
        fluid(maxWidth: 49, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      broker2: imageSharp(fluid: { originalName: { regex: "/broker-2/" }}) {
        fluid(maxWidth: 49, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  return pageQuery
}

export default PageQuery

import React from 'react'
import Img from 'gatsby-image'
import Tab from 'src/components/Tab'

import usePageQuery from '../../pageQuery'
import { modalProps } from '../hero/types'

import { Container } from './style'

interface ITabSection {
  title: string;
  description: string;
  observation?: string;
}

const Analise = ({ pageContext, type }: modalProps) => {
  const data = usePageQuery()
  const dataNow = new Date()
  const month = dataNow.getMonth()
  let referenceYear = dataNow.getFullYear()

  const arrayMonth = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  if (month === 0) {
    arrayMonth[month] = 'Dezembro'
    referenceYear = referenceYear - 1
  } else if (month === 1) {
    arrayMonth[month] = 'Janeiro'
  } else if (month === 2) {
    arrayMonth[month] = 'Fevereiro'
  } else if (month === 3) {
    arrayMonth[month] = 'Março'
  } else if (month === 4) {
    arrayMonth[month] = 'Abril'
  } else if (month === 5) {
    arrayMonth[month] = 'Maio'
  } else if (month === 6) {
    arrayMonth[month] = 'Junho'
  } else if (month === 7) {
    arrayMonth[month] = 'Julho'
  } else if (month === 8) {
    arrayMonth[month] = 'Agosto'
  } else if (month === 9) {
    arrayMonth[month] = 'Setembro'
  } else if (month === 10) {
    arrayMonth[month] = 'Outubro'
  } else if (month === 11) {
    arrayMonth[month] = 'Novembro'
  }

  return (
    <Container className='pt-5 pb-3 pb-md-4 py-md-5 d-flex position-relative justify-content-between align-items-center'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-4 col-lg-5 d-md-none d-lg-block'>
            <Img fluid={data.mulherAnalisandoFundos.fluid} alt='Assistir Vídeo' />
          </div>
          <div className='col col-12 col-lg-7 col-xl-6 pr-lg-0'>
            <h2 className='fs-24 lh-30 pt-4 pt-md-0 text-grayscale--500'>Análise do gestor</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500 mb-4'>
              Confira a análise do gestor e conheça mais sobre este fundo.
            </p>
            <Tab
              items={[ 'Sobre a gestora', 'Atribuição de performance', 'Posicionamento atual' ]}
              styles='fs-14 lh-17 mb-3'
              classDiv='tab-investimentos'
              color='#161616'
              activeColor={type === 'PJ' ? '#07605B' : '#FF7a00'}
            >
              {pageContext.analiseDoGestor.map((item: ITabSection) => (
                <div key={item.title}>
                  <p className='fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--500 pt-2 mb-0'>{item.description}</p>
                  {item.title !== 'Sobre a gestora' &&
                    <p className='fs-12 lh-15 text-grayscale--400 pt-3'>
                      Todos os comentários foram enviados diretamente pelos gestores e estão apresentados na íntegra, sem edições
                      feitas pelo Inter. Mês de referência: {arrayMonth[month]} de {referenceYear}.
                    </p>
                  }
                </div>
              ))}
            </Tab>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Analise

import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { green, orange } from 'src/styles/colors'
import TollTip from 'src/components/InvestmentsFilter/ToolTip/_ToolTip'

import { BoxContent } from './style'

interface IInfoBoxProps {
  iconName: string;
  title: string;
  hasTooltip?: boolean;
  children: React.ReactElement | React.ReactNode;
  icon?: boolean | string;
  tolltipTitle?: string;
  tooltipText?: string;
  styles?: string;
  type?: string;
}

const InfoBox = ({ iconName, title, children, hasTooltip, icon, tolltipTitle, tooltipText, styles, type }: IInfoBoxProps) => {
  return (
    <BoxContent>
      <OrangeIcon icon={iconName} color={type === 'PJ' ? green[500] : orange.extra} size='MD' />
      <div className={`d-flex align-items-center pb-2 pt-2 mt-auto ${styles}`}>
        <div className='d-flex align-items-center'>
          <h4 className='fs-12 lh-15 text-grayscale--500 mr-1'>
            {title}
          </h4>
          {icon === true &&
            (
              <div className='icon'>
                <TollTip
                  iconTooltip='help'
                  title={tolltipTitle}
                  text={tooltipText}
                  colorIcon={type === 'PJ' ? '#07605B' : '#FF7A00'}
                  sizeIcon='MD'
                />
              </div>
           )
          }
        </div>
        {hasTooltip && <OrangeIcon icon='help' color={type === 'PJ' ? green[500] : orange.extra} size='SM' />}
      </div>
      {children}
    </BoxContent>
  )
}

export default InfoBox

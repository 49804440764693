import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 210px);
  gap:20px;
`

export const Help = styled.div`
  float: right;
  @media ${device.desktopLG} {
    position: relative;
  }
  .tooltip {
    @media ${device.desktopLG} {
      left: -32px;
      top: 36px;
    }
  }
`

export const AdmRate = styled.span`
  color: #00AA4F;
  span {
    text-decoration: line-through;
    @media ${device.desktopLG} {
      text-decoration: none;
    }
  }
`

export const TolltipHelp = styled.div`
  svg {
    width: 17px;
  }
  .through {
    text-decoration: line-through;
  }
  .saleoff {
    position: relative;
    .tooltip {
      left: -157px;
      top: 33px !important;
      position: absolute;
  
      @media ${device.tablet} {
        top: 36px !important;
      }

      @media ${device.desktopLG} {
        left: -233px !important;
      }
      &:before {
        left: 158px;
        @media ${device.desktopLG} {
          left: 234px;
        }
      }
    }
  }
  .redemptionFeeLg {
    position: relative;
    .tooltip {
      left: -137px;
      top: 33px !important;
      position: absolute;
  
      @media ${device.tablet} {
        top: 36px !important;
      }

      @media ${device.desktopLG} {
        left: -233px !important;
      }
      &:before {
        left: 138px;
        @media ${device.desktopLG} {
          left: 234px;
        }
      }
    }
  }

  .performance {
    position: relative;
    .tooltip {
      left: -129px;
      top: 33px !important;
      position: absolute;
  
      @media ${device.tablet} {
        top: 36px !important;
      }

      @media ${device.desktopLG} {
        left: -233px !important;
      }
      &:before {
        left: 130px;
        @media ${device.desktopLG} {
          left: 234px;
        }
      }
    }
  }
`

export const Risk = styled.div`
  @media ${device.tablet} {
    margin-top: -10px;
  }

  span {
    position: relative;
    top: 8px;
  }

  .muito-baixo {
    color: #43B0F0;
  }
  .baixo {
    color: #00AA4F;
  }
  .medio {
    color: #FFB800;
  }  
  .alto {
    color: #F56A50;
  }
  .muito-alto {
    color: #C5232A
  }
`

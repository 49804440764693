import React from 'react'

import LogoAnbima from './images/logo-anbima.png'
import LogoAnbimaAdmFiduciaria from './images/logo-anbima-adm-fiduciaria.png'
import ComoInvestir from './images/logo-como-investir.png'
import LogoAnbimaDistribuicao from './images/logo-anbima-distribuicao-produtos.png'
import LogoAnbimaOfertas from './images/logo-anbima-ofertas-publicas.png'
import LogoAnbimaAutoRegulacao from './images/logo-anbima-auto-regulacao.png'
import RetailBroker from './images/retail-broker.png'
import ExecutionBroker from './images/execution-broker.png'

import { LogosSize, ListaLogos } from './style'

type langProps = {
  type?: string;
}

const Logos = ({ type }: langProps) => {
  return (
    <>
      <div className='row justify-content-center mt-3'>
        <LogosSize><img src={LogoAnbima} /></LogosSize>
        <LogosSize><img src={LogoAnbimaAdmFiduciaria} /></LogosSize>
        <LogosSize><img src={ComoInvestir} /></LogosSize>
        <LogosSize><img src={LogoAnbimaDistribuicao} /></LogosSize>
        <LogosSize><img src={LogoAnbimaOfertas} /></LogosSize>
        <LogosSize><img src={LogoAnbimaAutoRegulacao} /></LogosSize>
        <LogosSize><img src={RetailBroker} /></LogosSize>
        <LogosSize><img src={ExecutionBroker} /></LogosSize>
      </div>
      <div className='row justify-content-md-center mt-3'>
        <ListaLogos className='d-flex flex-wrap justify-content-center'>
          <li className='fs-14 lh-17 fs-lg-18 lh-lg-22 pr-2 pr-lg-3'>
            <a
              href='https://inter.co/inter-dtvm/tipos-de-risco'
              title='Tipos de risco'
              className={`${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`}
            >Tipos de risco
            </a>
          </li>
          <li className='fs-14 lh-17 fs-lg-18 lh-lg-22 px-2 px-lg-3'>
            <a
              href='https://www.gov.br/cvm/pt-br/canais_atendimento/consultas-reclamacoes-denuncias'
              target='_blank' title='Atendimento CVM'
              className={`${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`} rel='noreferrer'
            >Atendimento CVM
            </a>
          </li>
          <li className='fs-14 lh-17 fs-lg-18 lh-lg-22 px-2 px-lg-3'>
            <a
              href='https://www.bsmsupervisao.com.br/ressarcimento-de-prejuizos/como-funciona'
              target='_blank' title='Atendimento MRP'
              className={`${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`} rel='noreferrer'
            >Atendimento MRP
            </a>
          </li>
          <li className='fs-14 lh-17 fs-lg-18 lh-lg-22 px-2 px-md-2 px-lg-3'>
            <a
              href='https://www.gov.br/cvm/'
              target='_blank' title='CVM'
              className={`${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`} rel='noreferrer'
            >CVM
            </a>
          </li>
          <li className='fs-14 lh-17 fs-lg-18 lh-lg-22 px-2 px-lg-3'>
            <a
              href='https://www.b3.com.br/'
              className={`${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`}
              target='_blank'
              rel='noreferrer'
              title='BMF Bovespa'
            >BMF Bovespa
            </a>
          </li>
          <li className='fs-14 lh-17 fs-lg-18 lh-lg-22 px-2 px-lg-3 border-0'>
            <a
              href='https://www.bsmsupervisao.com.br/'
              target='_blank'
              rel='noreferrer'
              title='BSM'
              className={`${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`}
            >BSM
            </a>
          </li>
        </ListaLogos>
      </div>
    </>
  )
}

export default Logos

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const LogosSize = styled.div`
    padding: 20px;
    float: left;
    display: flex;
    
    @media ${device.tablet} {
      padding: 6px;
    }

    @media ${device.desktopLG} {
      padding: 14px;
    }

    @media ${device.desktopXL} {
      padding: 16px;
    }

  img {
    margin: 0 auto;

    @media ${device.tablet} {
      margin: 0 auto;
      height: 50px;
    }
  } 
`

export const ListaLogos = styled.ul`
  padding: 0;
  margin-bottom: 0;

  @media ${device.tablet} {
    text-align: left;
    margin: 0;
  }

  li {
    list-style: none;
    padding: 0 12px 0 0;
    font-weight: bold;
    border-right: 1px solid ${grayscale[500]};
    float: left;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      text-align: left;
      float: left;
    }
  }
`

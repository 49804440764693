import React from 'react'
import Img from 'gatsby-image'
import { green, orange } from 'src/styles/colors'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import usePageQuery from '../../pageQuery'

import { Documentation } from './style'

import { modalProps } from '../hero/types'
interface IItemDocumentacao {
  nome: string;
  link: string;
}

export interface ISendDatalayerEventsProps extends modalProps {
  sendDatalayerEvent: Function;
  type?: string;
}

const DocumentacaoFundos = ({ pageContext, sendDatalayerEvent, type }: ISendDatalayerEventsProps) => {
  const data = usePageQuery()

  return (
    <Documentation className='bg-white py-5 position-relative d-flex align-items-center justify-content-between'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 pb-4 pb-md-0'>
            <Img fluid={data.documentacaoFundos.fluid} alt='Mulher olhando o notebook' />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-md-30 lh-md-30 fs-lg-40 lh-lg-50 text-grayscale--500 pb-4'>Documentações do fundo</h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--500 pb-6'>
              Antes de investir, verifique as características do fundo e sempre aplique de acordo com o seu perfil de investimentos.
            </p>
            {pageContext.documentacaoDoFundo.map((item: IItemDocumentacao) => (
              <div key={item.nome} className='d-flex align-items-center mt-4'>
                <OrangeIcon color={type === 'PJ' ? green[500] : orange.extra} size='MD' icon='pdf-file' />
                <a
                  href={item.link}
                  target='_blank'
                  rel='noreferrer'
                  title='Documentação do fundo'
                  className={`fs-14 lh-17 pl-2 fw-600 ${type === 'PJ' ? 'text-green--500' : 'text-orange--extra'}`}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_5',
                      element_action: 'click button',
                      section_name: 'Documentações do fundo',
                      element_name: item.nome,
                      c_page: window.location.href,
                      redirect_url: item.link,
                    })
                  }}
                >{item.nome}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Documentation>
  )
}

export default DocumentacaoFundos

import React from 'react'
import { modalProps } from '../hero/types'

import TollTip from 'src/components/InvestmentsFilter/ToolTip/_ToolTip'

import { DivLine, TooltipHelp, HelpMembership } from './style'

const Tecnical = ({ pageContext, type }: modalProps) => {
  return (
    <section className='bg-grayscale--100 py-5 d-flex align-items-center justify-content-center flex-column'>
      <div className='container'>
        <h2 className='text-left text-md-center fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-md-3'>
          Especificações técnicas
        </h2>
        <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500 text-left text-md-center pb-3 pb-md-4'>
          Aqui estão as informações básicas do fundo, passadas pelo administrador ou gestor.
        </p>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Gestor</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.gestor}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>CNPJ</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.cnpj}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Classificação Inter</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.classificacaoInter}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Classificação CVM</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.filterData.fundClass}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Patrimônio líquido atual</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.plMedio}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Data de início</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.dataInicio}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Movimentação mínima</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.movimentacaoMinima}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Saldo mínimo de permanência</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.saldoMinimo}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <TooltipHelp className='d-flex align-items-center'>
              <h3 className='fs-12 lh-15 text-grayscale--500 mb-1 mr-1'>Cotização da aplicação</h3>
              <HelpMembership className='mb-1 membership'>
                <TollTip
                  iconTooltip='help'
                  title={pageContext.pageData.cotizacao.title}
                  text={pageContext.pageData.cotizacao.description}
                  colorIcon={type === 'PJ' ? '#07605B' : '#FF7A00'}
                  sizeIcon='MD'
                />
              </HelpMembership>
            </TooltipHelp>
            <span className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>
              {pageContext.pageData.cotizacao.cotizacaoDaAplicacao} {pageContext.filterData.redemptionFee.workingDays === true ? '(dias úteis)' : '(dias corridos)'}
            </span>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <TooltipHelp className='d-flex align-items-center'>
              <h3 className='fs-12 lh-15 text-grayscale--500 mb-1 mr-1'>Horário limite para movimentações</h3>
              <HelpMembership className='mb-1 time-limit'>
                <TollTip
                  iconTooltip='help'
                  title={pageContext.pageData.movimentacao.title}
                  text={pageContext.pageData.movimentacao.description}
                  colorIcon={type === 'PJ' ? '#07605B' : '#FF7A00'}
                  sizeIcon='MD'
                />
              </HelpMembership>
            </TooltipHelp>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.movimentacao.horarioLimiteMovimentacao}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Administrador</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.administrador}</p>
            <DivLine />
          </div>
          <div className='col-12 col-md-5'>
            <h3 className='fs-12 lh-15 text-grayscale--500 mb-1'>Tributação</h3>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-2'>{pageContext.pageData.tributacao}</p>
            <DivLine />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tecnical
